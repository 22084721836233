$color-primary:  #643DBC;
$color-primary-text:  #633ABB;
$color-primary-20:  #643dbc1f;
$color-primary-dark-text: #302F44;
$color-title: #000C2F;
$color-p: #90929C;
$color-p-dark: #353448;

input, textarea, input:focus, textarea:focus, button:focus{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0!important;
    font-size: 100%;
}
p{
    color: $color-p;
}

.mb-50{
    margin-bottom: 50px;
}
body{
    color: #4a4a4a;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    & p{
        text-align: justify;
    }

    & em{
        font-style: normal;
    }
    & h2.title{
        font-size: 35px;
        line-height: 47px;
        color: $color-title;
        font-weight: 600;
    }


    #page-content{
        zoom: 1;
        min-height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        & .container-fluid{
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        &::before{
            content: "";
            display: block;
            width: 100vw;
            height: 180vh;
            margin: auto;
            background-image: -webkit-gradient(linear, left top, left bottom, from($color-primary-20), to(#fff));
            background-image: -webkit-linear-gradient(top, $color-primary-20, #fff);
            background-image: -o-linear-gradient(top, $color-primary-20, #fff);
            background-image: linear-gradient(to bottom, $color-primary-20, #fff);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        & section, footer{
            & .container-fluid{
                position: relative;
                z-index: 2;
                padding-bottom: 3.75rem;
                @media (min-width: 992px){
                    max-width: 1180px;
                }
                @media (min-width: 1450px){
                    max-width: 1380px;
                }
            }
        }

        & .navbar{
            color: $color-primary;
            padding: 24px 0;
            -webkit-transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1) 0s;
            -o-transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1) 0s;
            transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1) 0s;
            @media (min-width: 992px){
                padding: 24px 40px;
            }

            &>.container-fluid{
                max-width: 1410px;
            }
            &.--scrolled{
                background-color: #fff;
                -webkit-box-shadow: 0 -12px 35px 0 rgba(0,0,0,0.2);
                box-shadow: 0 -12px 35px 0 rgba(0,0,0,0.2);
                padding: 10px 0;
                @media (min-width: 992px){
                    padding: 10px 40px;
                }
            }
            & .navbar-brand{
                color: $color-primary;
                font-family: 'Paysy-logo'!important;
                font-weight: bold;
                font-size: 40px !important;
                line-height: 56px;
                padding: 0;
                margin-top: -5px;
            }
            & .nav-item{
                margin-right: 32px;
                @media(max-width:992px){
                    margin-right: 0;
                }

                & .btn{
                    font-size: 15px;
                    font-weight: 600;
                    margin: 9px 0px;
                    padding: 6px 25px;
                    @media(max-width:992px){
                        font-size: 20px;
                    }
                }
            }
            & .nav-link{
                font-size: 15px;
                font-weight: 800;
                text-transform: capitalize;
                line-height: 40px;
                &.dropdown-toggle:active, &.dropdown-toggle:focus, &.dropdown-toggle:visited{
                    outline: none;
                }
            }
            & .ddm-locale{
                & .nav-link{
                    font-weight: 600!important;
                    color: #4a4a4a;
                    font-size: 14px;
                }
            }

            & .ddm-locale{
                @media(max-width: 992px){
                    border: 0;
                    border-radius: 0;
                    background-image: -o-linear-gradient(#eee 0%, white 15%);
                    background-image: linear-gradient(#eee 0%, white 15%);



                    & .ddi-locale{
                        position: relative;
                        width: 33%;
                        left: 35%;
                        padding: .5rem 0;
                    }
                }

                & img{
                    width: 20px;
                    float: right;
                    margin-top: 4px;
                }
            }

            & .ddm-products{

                & a{
                    text-decoration: none;
                    &:hover div{
                        opacity: .8;
                    }
                }

                @media(min-width: 993px){
                    width: 800px;
                    left: 230px;
                    top:80px;
                    padding: 25px;
                    border-radius: 12px;
                    border: 0;
                }
                @media(min-width: 1460px){
                    left:630px;
                }

                & .title{
                    @media(min-width: 993px){
                        margin-bottom: 20px;
                    }
                    & img{
                        float: left;
                        @media(min-width: 993px){
                            width: 78px;
                            height: 78px;
                            margin-right: 17px;
                        }
                    }
                    & h1{

                        @media(min-width: 993px){
                            color: #353448;
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: 700;
                            padding: 24px 17px;
                        }
                    }
                    & p{
                        @media(min-width: 993px){
                            color: #90929c;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;
                            padding-left: 95px;
                            text-align: left;
                        }
                    }
                }
                & .item{
                    @media(min-width: 993px){
                        margin-bottom: 20px;
                    }
                    & img{
                        float: left;
                        @media(min-width: 993px){
                            height: 18px;
                            margin-right: 18px;
                        }
                    }
                    & h2{

                        @media(min-width: 993px){
                            color: #353448;
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 18px;
                        }
                    }
                }
            }

            & .navbar-toggler{
                margin-top: 4px;
                margin-bottom: 4px;
                width: 26px;
                height: 26px;
                padding: 2px 0;
                border: none;
                right: 15px;

                & .navbar-toggler-bar{
                    display: block;
                    width: 26px;
                    height: 2px;
                    background: $color-primary;
                    -webkit-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                    -o-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                    transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                    border-radius: 4px;
                    margin-bottom: 5px;
                }

                &.active{

                    & .navbar-toggler-bar{
                        display: block;
                        width: 26px;
                        height: 2px;
                        -webkit-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                        -o-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                        transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                        border-radius: 4px;
                        margin-bottom: 5px;

                        &:nth-child(1){
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: relative;
                            top: 7px;
                        }
                        &:nth-child(2){
                            opacity: 0;
                        }
                        &:nth-child(3){
                            -webkit-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            position: relative;
                            bottom: 7px;
                        }
                    }
                }
            }
            &.mobile-nav-active{
                @media (max-width: 992px){
                    background-color: rgba(255,255,255,0.95);
                }

                transition: height 0.5s cubic-bezier(0.22, 1, 0.36, 1);
            }
            .mobile-nav{
                top: 71px;
                background-color: rgba(255,255,255,0.95);
                display: block;
                height: 0;

                & .nav-item{
                    visibility: hidden;
                    opacity: 0;
                    transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);
                    padding: 0;

                    & .nav-link{
                        font-size: 22px;
                    }
                }

                & .dropdown.first{
                    height: 56px;
                    & .dropdown-menu{

                        & .nav-item{
                            font-size: 15px;
                            line-height: 18px;
                            font-weight: 700;
                            position: relative;
                            left: -10px;
                            & img{
                                float: left;
                                width: 25px;
                                height: 25px;
                                position: relative;
                                left: 20px;
                            }

                            &.small{
                                color: #353448;
                                font-size: 12px;
                                line-height: 18px;
                                font-weight: 600;
                                & img{
                                    float: left;
                                    width: 21px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
                & .dropdown.first.show{
                    height: 360px;
                    & > ul{
                        padding: 10px;
                        top:56px;
                        width: 100%;
                        border: 0;
                        border-radius: 0;
                        box-shadow: inset 0 0em 3em rgba(0,0,0,0.1);
                    }
                }
                & .dropdown{
                    -webkit-transition: height 0.3s ease-out;
                    -moz-transition: height 0.3s ease-out;
                    -ms-transition: height 0.3s ease-out;
                    -o-transition: height 0.3s ease-out;
                    transition: height 0.3s ease-out;
                }
            }
            &.mobile-nav-active .mobile-nav{
                transition: height 0.5s cubic-bezier(0.22, 1, 0.36, 1);
                height: calc(100% - 71px);

                & ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    padding-top: 40px;
                    text-align: center;
                }
                & .nav-item{
                    visibility: inherit;
                    opacity: 1;
                    transform: matrix(1, 0, 0, 1, 0, 0);
                    z-index: 0;
                }
            }

        }

        & #intro{
            padding-top: 100px;
            position: relative;

            & h1{
                font-size: 35px;
                line-height: 37px;
                padding-top: 20px;
                letter-spacing: 0.43px;

                @media (min-width: 992px){
                    font-size: 70px;
                    line-height: 75px;
                    padding-top: 45px;
                    max-width: 580px;
                }

                & em{
                    color: $color-primary;
                    font-size: 50px;
                    line-height: 37px;
                    font-style: normal;
                    white-space: nowrap;
                    @media (min-width: 992px){
                        font-size: 100px;
                        line-height: 75px;
                    }
                }
            }

            & p{
                font-size: 15px;
                line-height: 25px;
                padding-top: 30px;
                color: $color-p;
                @media (min-width: 992px){
                    max-width: 600px;
                }

                & em{
                    font-style: normal;
                    font-weight: 700;
                }
            }

            & span.play{
                position: absolute;
                top: 200px;
                left: 890px;
                width: 85px;
                height: 85px;
                border-radius: 50%;
                background-color: $color-primary;
                opacity: .5;

                & i{
                    color: #fff;
                    font-size: 35px;
                    line-height: 85px;
                    text-align: center;
                    position: relative;
                    left: 31px;
                    cursor: pointer;
                }
                @media(max-width: 992px){
                    top: 580px;
                    left: calc(50% - 42px);
                    width: 85px;
                    height: 85px;
                }
            }

            & img.bg{
                position: absolute;
                top: 420px;
                left: -25px;
                width: 445px;
                @media (min-width: 993px){
                    top: 0px;
                    left: 590px;
                    width: 1000px;
                }
            }
            & .trial{
                margin-top: 35px;
                @media (max-width: 767px){
                    text-align: center !important;
                }

                & .btn{
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    padding: 10px 35px;
                }

                & div{
                    font-size: 13px;
                    font-weight: 600;
                    padding-left: 10px;

                }
            }
            & .clubs{
                max-width: 325px;
                margin:auto;
                margin-top: 590px;
                @media (min-width: 1200px){
                    margin: 0;
                    margin-top: 135px;
                    max-width: 560px;
                }

                & img
                {
                    width: 90px;
                    height: 90px;
                    margin-left: 35px;
                    margin-right: 35px;
                    margin-bottom: 50px;
                    @media (min-width: 1200px){
                        margin-left: 0;
                        margin-right: 64px;
                        margin-bottom: 44px;
                    }
                }
                & p{
                    padding: 0;
                    padding-left: 10px;
                    margin-top: -20px;
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 600;
                    text-align: center;
                    @media (min-width: 1200px){
                        text-align: left;
                    }

                    & em{
                        color: $color-primary;
                        font-size: 20px;
                        line-height: 25px;
                        font-weight: 600;
                    }
                }
            }
        }

        & #stats{
            @media(min-width:993px){
                padding-top: 180px;

                & .container-fluid{
                    padding-bottom: 0px;
                }
            }

            & .stats{
                margin-bottom: 40px;
                @media(min-width:993px){
                    margin-bottom: 0px;
                }
                & h1{
                    color: $color-primary;
                    font-size: 45px;
                    line-height: 45px;
                    font-weight: 700;

                    @media(min-width:993px){
                        font-size: 70px;
                        line-height: 80px;
                    }
                }
                & p{
                    font-size: 18px;
                    line-height: 22px;
                    max-width: 300px;
                    margin-bottom: 0;
                    @media(min-width:993px){

                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        & #tabs{
            margin-top: 30px;
            @media(min-width:1200px){
                margin-top: 150px;
            }


            & #tabs-container{
                height: 1060px;
                background-color: #353448;
                border-radius: 20px 20px 20px 20px;
                padding-top: 30px;
                margin: 0 -15px;
                @media(min-width:993px){
                    margin: 0;
                }
                @media(min-width:1200px){
                    border-radius: 140px 20px 20px 20px;
                    padding-top: 140px;
                    width: 1115px;
                }

                & #tabs-headers-container{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-left: 20px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #434254;

                    @media(min-width:1200px){
                        border: 0;
                        padding-left: 120px;
                    }
                    & #tabs-select-bar{
                        z-index: 999;
                        background-color: #A385FF;
                        position: absolute;
                        top: 193px;
                        left: 132px;
                        width: 95px;
                        height: 3px;
                        transition: left 0.5s cubic-bezier(0.22, 1, 0.36, 1);
                        display: none;
                        @media(min-width:1200px){
                            display: block;
                        }
                    }

                    & .tab-header{
                        color: #fff;
                        width: 105px;
                        cursor: pointer;

                        @media(min-width:1200px){
                            width: 175px;
                        }
                        & .icon{
                            width: 100%;
                            padding-left: 2px;
                            padding-bottom: 10px;
                            @media(min-width:1200px){
                                padding-bottom: 22px;
                                border-bottom: 1px solid #979797;
                            }
                            &>img{
                                height: 32px;
                                transition: color 0.4s cubic-bezier(0.22, 1, 0.36, 1);
                                filter: invert(100%) sepia(0%) saturate(7472%) hue-rotate(97deg) brightness(113%) contrast(111%);
                            }
                        }

                        &.selected{
                            color: #A385FF;

                            & .icon>img{
                                filter: invert(56%) sepia(18%) saturate(1410%) hue-rotate(213deg) brightness(100%) contrast(103%);
                            }
                        }

                        & i{
                            font-size: 24px;
                            width: 100%;
                            padding-left: 2px;
                            padding-bottom: 10px;
                            transition: color 0.4s cubic-bezier(0.22, 1, 0.36, 1);

                            @media(min-width:1200px){
                                padding-bottom: 22px;
                                border-bottom: 1px solid #979797;
                            }

                        }
                        & p{
                            max-width: 130px;
                            font-size: 15px;
                            line-height: 19px;
                            font-weight: 600;
                            padding-left: 2px;
                            transition: color 0.4s cubic-bezier(0.22, 1, 0.36, 1);

                            @media(min-width:1200px){
                                margin-top: 24px;
                            }

                        }
                    }
                }
                & .content{
                    color: #fff;
                    padding-top: 30px;
                    padding-left: 20px;
                    padding-right: 20px;
                    @media(min-width:1200px){
                        padding-left: 120px;
                        padding-top: 0;
                    }

                    & #tab-heading{
                        font-size: 28px;
                        line-height: 28px;
                        font-weight: 600;
                        text-transform: capitalize;
                        @media(min-width:1200px){
                            max-width: 400px;
                            font-size: 57px;
                            line-height: 67px;
                            padding-top: 70px;
                        }
                    }
                    & #tab-text{
                        margin-top: 35px;
                        font-size: 15px;
                        line-height: 24px;
                        max-width: 320px;
                    }
                    & #tab-img{
                        position: absolute;
                        background-size: cover;
                        width: 450px;
                        height: 240px;
                        left : 40px;
                        bottom: 50px;
                        border-radius: 10px;

                        @media(min-width:1200px){
                            width: 1000px;
                            height: 540px;
                            left : 520px;
                            bottom: 110px;
                            border-radius: 20px;
                        }
                    }

                    & #tab-indents{
                        padding-top: 15px;
                        & .indent{
                            font-size: 17px;
                            line-height: 17px;
                            font-weight: 600;
                            padding-top: 24px;
                            padding-bottom: 24px;
                            max-width: 345px;
                            border-bottom: 1px solid #434254;
                            & img{
                                float: left;
                                margin-top: -11px;
                                -webkit-transform: scale(0.5); /* Saf3.1+, Chrome */
                                -moz-transform: scale(0.5); /* FF3.5+ */
                                -ms-transform: scale(0.5); /* IE9 */
                                -o-transform: scale(0.5); /* Opera 10.5+ */
                                transform: scale(0.5);
                            }

                            & i{
                                float: left;
                                color: $color-primary;
                                font-size: 20px;
                            }
                            & p{
                                margin:0;
                                margin-left: 60px;
                                line-height: 20px;
                                text-align: left;
                            }
                            &:last-child{
                                border-bottom: 0!important;
                            }
                        }
                    }
                }




            }
        }

        & #features{

            & .ordred{
                margin-top: 50px;
                @media(min-width:993px){
                    margin-top: 115px;
                }

                @media(min-width:993px){
                    height: 400px;
                    &:nth-child(even){
                        &>.content{
                            order: 2;
                        }
                        &>.image{
                            order: 1;
                        }
                    }
                }
                @media(min-width:1450px){
                    &:nth-child(even){
                        &>.content{
                            padding-left: 120px;
                        }
                    }
                }
                &.export{
                    background-color: #E0D9F5;
                    border-radius: 20px 120px;
                    @media(min-width:993px){
                        height: 500px;
                    }
                    &>.image{
                        background-size: contain;
                        margin-bottom: 60px;
                        @media(min-width:993px){
                            margin-bottom: 50px;
                            margin-top: 50px;
                        }
                    }
                    &>.content{
                        padding-top: 50px;
                    }
                }

                &>.content{

                    &>h1{
                        color: #000;
                        font-size: 35px;
                        font-weight: 600;
                        line-height: 35px;

                        @media(min-width:993px){
                            margin-top: 95px;
                        }
                    }
                    &>h2{
                        color: $color-primary;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 22px;
                        margin-top: 8px;
                    }
                    &>p{
                        font-size: 15px;
                        line-height: 25px;
                        max-width: 450px;
                        margin-top: 25px;
                        @media(min-width: 1450px){
                            max-width: 520px;
                        }
                    }
                }
                &>.image{
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    min-height: 220px;
                    margin: 30px 20px;
                    @media(min-width:993px){
                        margin: 0;
                    }
                }
            }
        }

        & #referencies{
            @media(min-width:993px){
                margin-top: 120px;
            }
            & .left{
                position: relative;

                & .visual{
                    position: relative;
                    min-height: 400px;
                    margin-top: 60px;
                }

                @media(min-width:993px){
                    &>.visual{
                        padding-left: 35px;
                    }
                }

                @media(min-width:1450px){

                }
                @media(max-width:992px){
                    order: 2;
                }
                &>img.ref-image{
                    width: 100%;
                    @media(min-width:993px){
                        width: 500px;
                    }
                }

                &>.visual>img.quotes{
                    z-index: 150;
                    position: absolute;
                    background-color: $color-primary;
                    padding: 23px 23px;
                    border-radius: 30px 7px 7px 7px;
                    height: 60px;
                    left: 5px;
                    top: -40px;

                    @media(min-width:993px){
                        padding: 38px 36px;
                        border-radius: 50px 10px 10px 10px;
                        width: 100px;
                        height: 100px;
                        left: 0px;
                        top: 125px;
                    }
                }
                &>.visual>.bg{
                    z-index: 100;
                    position: absolute;
                    width: 250px;
                    height: 360px;
                    background-color: #e0d9f5;
                    @media(max-width:992px){
                        left:40px;
                        border-radius: 8px 8px 8px 150px;
                    }

                    @media(min-width:993px){
                        top: 180px;
                        width: 430px;
                        height: 430px;
                        border-radius: 10px 10px 10px 195px;
                    }
                }
                &>.visual>img.main{
                    z-index: 101;
                    position: absolute;
                    width: 250px;
                    height: 360px;
                    @media(max-width:992px){
                        left:40px;
                    }

                    @media(min-width:993px){
                        height: 610px;
                        width: 430px;
                    }
                }
                &>.resume{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;

                    @media(min-width:993px){
                        width: 500px;
                    }

                    & img.org{
                        height: 96px;
                        float: left;
                        margin-right: 30px;
                    }
                    & .content{
                        & h1{
                            font-size: 25px;
                            font-weight: 600;
                            line-height: 34px;
                            color: #353448;
                        }
                        & h2{
                            font-size: 15px;
                            line-height: 20px;
                            color: #b9bec8;
                        }
                        & .rating{
                            font-size: 15px;
                            color: $color-primary;
                        }
                    }
                }
            }
            & .right{
                @media(max-width:992px){
                    order: 1;
                }
                &>.quotes{
                    width: 51px;
                    height: 51px;
                    border-radius: 9px;
                    background-color: #E0D9F5;
                    position: relative;
                    top:24px;
                    padding: 13px 14px;
                    @media(min-width: 993px){
                        width: 60px;
                        height: 60px;
                        border-radius: 10px;
                        background-color: #E0D9F5;
                        top:18px;
                        padding: 16px;

                    }

                    & img{
                        width: 20px;
                        @media(min-width: 993px){
                            width: 26px;
                        }
                    }
                }

                &>h1{
                    font-size: 35px;
                    line-height: 40px;
                    color: $color-primary-text;
                    margin-top: 50px;

                    font-weight: 600;
                    @media(min-width: 993px){
                        font-size: 67px;
                        line-height: 75px;
                        max-width: 10ch;
                    }

                }
                &>p{
                    font-size: 15px;
                    line-height: 25px;
                    margin-top: 50px;
                    max-width: 540px;
                    color: #4F4E5F;
                    @media(min-width: 993px){
                        font-size: 17px;
                        line-height: 29px;
                        margin-top: 60px;
                    }

                }
            }
        }

        & #contact{
            @media(min-width:993px){
                margin-top: 70px;
            }

            & h1{
                color: $color-primary-text;
                font-size: 22px;
                line-height: 22px;
                font-weight: 700;
                margin-bottom: 20px;
                margin-top: 25px;
            }

            & .contact-info{

                & p.email{
                    font-size: 15px;
                    line-height: 15px;
                    & a{
                        color: inherit;
                        &:hover{
                            color: inherit;
                            text-decoration: none;
                        }
                    }

                }
                & p.phone{
                    font-size: 14px;
                    line-height: 24px;
                    margin-top: 10px;
                }
                & p.note{
                    font-size: 15px;
                    line-height: 17px;
                    margin-top: 15px;
                }
                & .social{
                    @media(min-width:993px){
                        margin-top: 30px;
                    }
                    & .follow{
                        font-size: 40px;
                        line-height: 40px;
                        margin-top: 15px;
                        &>a{
                            color: #000;
                        }
                    }
                }
            }

            & #contact-form{

                & h1{
                    padding-left: 10px;
                }
                & .input{
                    width: 100%;
                    padding: 5px;

                    & input{
                        width: 100%;
                        font-size: 15px;
                        line-height: 25px;
                        padding: 10px;
                        background: transparent;
                        outline: 0;
                        border: 1px solid #e6e6e6;
                        border-radius: 5px;

                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        outline: 0!important;

                        &::placeholder{
                            // text-transform: capitalize;
                        }
                        &.invalid{
                            border-color: $color-primary !important;
                        }
                    }
                    & textarea{
                        width: 100%;
                        line-height: 25px;
                        height: 110px;
                        resize: none;
                        padding: 10px;
                        background: transparent;
                        outline: 0;
                        border: 1px solid #e6e6e6;
                        border-radius: 5px;

                        &.invalid{
                            border-color: $color-primary !important;
                        }
                    }
                    & button{
                        width: 100%;
                        font-size: 16px;
                        font-weight: 600;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }
                }
            }
        }

        & #presentation-form{
            & .modal-content{
                border-radius: 20px;
                & .modal-header{
                    background-color: #E0D9F5;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    border-radius: 20px 20px 0 0;
                    & h1{
                        font-size: 20px;
                        font-weight: 600;
                        color: $color-primary;
                        text-align: center;
                        width: 100%;
                        margin-bottom: 0;
                    }
                }
                & .modal-body{
                    & img {
                        width: 100%;
                        padding: 0 60px;
                        display: none;
                        margin-top: 20px;
                        margin-bottom: 110px;
                    }
                    & p{
                        width: 100%;
                        padding: 25px 40px;
                        font-size: 15px;
                        line-height: 20px;
                        font-weight: 500;
                        text-align: center;
                    }
                    & .input{
                        width: 100%;
                        padding: 5px 60px;

                        & input{
                            width: 100%;
                            font-size: 15px;
                            line-height: 25px;
                            padding: 10px;
                            background: transparent;
                            outline: 0;
                            border: 1px solid #e6e6e6;
                            border-radius: 5px;

                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            outline: 0!important;

                            &.invalid{
                                border-color: $color-primary !important;
                            }
                        }
                        & textarea{
                            width: 100%;
                            line-height: 25px;
                            height: 110px;
                            resize: none;
                            padding: 10px;
                            background: transparent;
                            outline: 0;
                            border: 1px solid #e6e6e6;
                            border-radius: 5px;

                            &.invalid{
                                border-color: $color-primary !important;
                            }
                        }
                        & button{
                            width: 100%;
                            font-size: 16px;
                            font-weight: 600;
                            padding-top: 16px;
                            padding-bottom: 16px;
                            margin-top: 65px;
                            margin-bottom: 50px;
                        }
                    }
                }
            }
        }

        #modal-video{
            padding-right: 0;
            & .modal-content{
                align-items: center;
                border: none;
                border-radius: 0;
                background-color: unset;

                & video{
                    width: 100%;
                    @media(min-width: 993px){
                        width: 960px;
                        height: 540px;
                    }
                }
            }
        }

        & footer{
            color: #fff;
            background-color: #000;
            font-size: 15px;
            line-height: 15px;
            padding-top: 35px;
            z-index: 10;
            & .container-fluid{
                padding-bottom: 0;
                @media(max-width:992px){
                    text-align: center !important;
                }
            }

            & .trial{
                font-size: 30px;
                line-height: 35px;
                font-weight: 200;
                @media(max-width: 992px){
                    max-width: 17ch;
                    margin: auto;
                }

                @media(min-width: 993px){
                    font-size: 35px;
                }

                &>em{
                    font-weight: 600;
                }
            }
            & .btn{
                font-weight: 600;
                padding: 16px 25px;
                margin-top: 35px;
                background-color: #A385FF !important;
            }

            & .bb{
                padding-top: 35px;
                padding-bottom: 50px;
                border-bottom: 1px solid #4d4c4c;
            }

            & h1{
                font-size: 14px;
                font-weight: 800;
                line-height: 22px;
                text-transform: uppercase;
                margin-top: 10px;
            }

            & h1.logo{
                font-family: 'Paysy-logo'!important;
                font-size: 40px !important;
                line-height: 40px !important;
                text-transform: lowercase !important;
                margin-top: 0;
            }

            & ul{
                list-style: none;
                display: inline-block;
                padding-left: 0;
                margin-top: 14px;

                & li{
                    margin-bottom: 12px;

                    & a{
                        color: inherit;
                        & img{
                            width: 150px;
                            @media(min-width: 993px){
                                width: 150px;
                            }
                        }
                    }

                    & .violet{
                        color: #A385FF !important;
                        font-size: 18px;
                    }
                }
            }
            & .copy{
                font-size: 12px;
                line-height: 12px;
                padding: 20px 0;
            }
        }

        & #more{
            padding-top: 30px;
            padding-bottom: 70px;

            & .bg{
                background-color: #E0D9F5;
                border-radius: 20px;
            }
            & .btn{
                font-weight: 600;
                padding: 16px 25px;
                margin-bottom: 50px;
                @media(min-width:993px){
                    position: absolute;
                    top: 50%;
                    left: 30%;
                    transform: translateY(-50%);
                }
            }

            & .more{
                border-radius: 20px;
                padding-top: 60px;
                padding-bottom: 10px;
                @media(min-width:993px){
                    padding-bottom: 60px;
                }

                & h1{
                    font-size: 35px;
                    line-height: 35px;
                    font-weight: 600;
                    color: $color-primary;
                    text-align: center;
                    @media(min-width:993px){
                        text-align: left;
                        float: right;
                        width: 450px;
                        margin-right: 15px;
                        font-size: 45px;
                        line-height: 45px;
                    }
                }
                & p{
                    margin-top: 17px;
                    margin-bottom: 25px;
                    text-align: justify;
                    @media(min-width:993px){
                        float: right;
                        width: 450px;
                        margin-right: 15px;
                        font-size: 14px;
                        line-height: 26px;
                        text-align: justify;
                    }
                }
            }

        }
    }

    #grands-content{
        zoom: 1;
        min-height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        & .container-fluid{
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        &:before{
            content: "";
            display: block;
            width: 100vw;
            height: 750px;
            margin: auto;
            background-image: linear-gradient(to bottom, #D8CEEF, #F4F1FB);
            position: absolute;
            top: -85px;
            transform: skewY(-5deg);
        }

        & section, footer{
            & .container-fluid{
                position: relative;
                z-index: 2;
                padding-bottom: 3.75rem;
                @media (min-width: 992px){
                    max-width: 1180px;
                }
                @media (min-width: 1450px){
                    max-width: 1380px;
                }
            }
        }

        & .navbar{
            color: $color-primary;
            padding: 24px 0;
            -webkit-transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1) 0s;
            -o-transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1) 0s;
            transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1) 0s;
            @media (min-width: 992px){
                padding: 24px 40px;
            }

            &>.container-fluid{
                max-width: 1410px;
            }
            &.--scrolled{
                background-color: #fff;
                -webkit-box-shadow: 0 -12px 35px 0 rgba(0,0,0,0.2);
                box-shadow: 0 -12px 35px 0 rgba(0,0,0,0.2);
                padding: 10px 0;
                @media (min-width: 992px){
                    padding: 10px 40px;
                }
            }
            & .navbar-brand{
                color: $color-primary;
                font-family: 'Paysy-logo'!important;
                font-weight: bold;
                font-size: 40px !important;
                line-height: 56px;
                padding: 0;
                margin-top: -5px;
            }
            & .nav-item{
                margin-right: 32px;
                @media(max-width:992px){
                    margin-right: 0;
                }

                & .btn{
                    font-size: 15px;
                    font-weight: 600;
                    margin: 9px 0px;
                    padding: 6px 25px;
                    @media(max-width:992px){
                        font-size: 20px;
                    }
                }
            }
            & .nav-link{
                font-size: 15px;
                font-weight: 800;
                text-transform: capitalize;
                line-height: 40px;
                &.dropdown-toggle:active, &.dropdown-toggle:focus, &.dropdown-toggle:visited{
                    outline: none;
                }
            }
            & .ddm-locale{
                & .nav-link{
                    font-weight: 600!important;
                    color: #4a4a4a;
                    font-size: 14px;
                }
            }

            & .ddm-locale{
                @media(max-width: 992px){
                    border: 0;
                    border-radius: 0;
                    background-image: -o-linear-gradient(#eee 0%, white 15%);
                    background-image: linear-gradient(#eee 0%, white 15%);



                    & .ddi-locale{
                        position: relative;
                        width: 33%;
                        left: 35%;
                        padding: .5rem 0;
                    }
                }

                & img{
                    width: 20px;
                    float: right;
                    margin-top: 4px;
                }
            }

            & .ddm-products{

                & a{
                    text-decoration: none;
                    &:hover div{
                        opacity: .8;
                    }
                }

                @media(min-width: 993px){
                    width: 800px;
                    left: 230px;
                    top:80px;
                    padding: 25px;
                    border-radius: 12px;
                    border: 0;
                }
                @media(min-width: 1460px){
                    left:630px;
                }

                & .title{
                    @media(min-width: 993px){
                        margin-bottom: 20px;
                    }
                    & img{
                        float: left;
                        @media(min-width: 993px){
                            width: 78px;
                            height: 78px;
                            margin-right: 17px;
                        }
                    }
                    & h1{

                        @media(min-width: 993px){
                            color: #353448;
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: 700;
                            padding: 24px 17px;
                        }
                    }
                    & p{
                        @media(min-width: 993px){
                            color: #90929c;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;
                            padding-left: 95px;
                            text-align: left;
                        }
                    }
                }
                & .item{
                    @media(min-width: 993px){
                        margin-bottom: 20px;
                    }
                    & img{
                        float: left;
                        @media(min-width: 993px){
                            height: 18px;
                            margin-right: 18px;
                        }
                    }
                    & h2{

                        @media(min-width: 993px){
                            color: #353448;
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 18px;
                        }
                    }
                }
            }

            & .navbar-toggler{
                margin-top: 4px;
                margin-bottom: 4px;
                width: 26px;
                height: 26px;
                padding: 2px 0;
                border: none;
                right: 15px;

                & .navbar-toggler-bar{
                    display: block;
                    width: 26px;
                    height: 2px;
                    background: $color-primary;
                    -webkit-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                    -o-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                    transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                    border-radius: 4px;
                    margin-bottom: 5px;
                }

                &.active{

                    & .navbar-toggler-bar{
                        display: block;
                        width: 26px;
                        height: 2px;
                        -webkit-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                        -o-transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                        transition: all 0.3s cubic-bezier(0.555, 0.205, 0.295, 0.975) 0s;
                        border-radius: 4px;
                        margin-bottom: 5px;

                        &:nth-child(1){
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: relative;
                            top: 7px;
                        }
                        &:nth-child(2){
                            opacity: 0;
                        }
                        &:nth-child(3){
                            -webkit-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            position: relative;
                            bottom: 7px;
                        }
                    }
                }
            }
            &.mobile-nav-active{
                @media (max-width: 992px){
                    background-color: rgba(255,255,255,0.95);
                }
                transition: height 0.5s cubic-bezier(0.22, 1, 0.36, 1);
            }
            .mobile-nav{
                top: 71px;
                background-color: rgba(255,255,255,0.95);
                display: block;
                height: 0;

                & .nav-item{
                    visibility: hidden;
                    opacity: 0;
                    transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);
                    padding: 0;

                    & .nav-link{
                        font-size: 22px;
                    }
                }

                & .dropdown.first{
                    height: 56px;
                    & .dropdown-menu{
                        & .nav-item{
                            font-size: 15px;
                            line-height: 18px;
                            font-weight: 700;
                            position: relative;
                            left: -10px;
                            & img{
                                float: left;
                                width: 25px;
                                height: 25px;
                                position: relative;
                                left: 20px;
                            }

                            &.small{
                                color: #353448;
                                font-size: 12px;
                                line-height: 18px;
                                font-weight: 600;
                                & img{
                                    float: left;
                                    width: 21px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
                & .dropdown.first.show{
                    height: 360px;
                    & > ul{
                        padding: 10px;
                        top:56px;
                        width: 100%;
                        border: 0;
                        border-radius: 0;
                        box-shadow: inset 0 0em 3em rgba(0,0,0,0.1);
                    }
                }
                & .dropdown{
                    -webkit-transition: height 0.3s ease-out;
                    -moz-transition: height 0.3s ease-out;
                    -ms-transition: height 0.3s ease-out;
                    -o-transition: height 0.3s ease-out;
                    transition: height 0.3s ease-out;
                }
            }
            &.mobile-nav-active .mobile-nav{
                transition: height 0.5s cubic-bezier(0.22, 1, 0.36, 1);
                height: calc(100% - 71px);

                & ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    padding-top: 40px;
                    text-align: center;
                }
                & .nav-item{
                    visibility: inherit;
                    opacity: 1;
                    transform: matrix(1, 0, 0, 1, 0, 0);
                    z-index: 0;
                }
            }

        }
        & footer{
            color: #fff;
            background-color: #000;
            font-size: 15px;
            line-height: 15px;
            padding-top: 35px;
            z-index: 10;
            & .container-fluid{
                padding-bottom: 0;
                @media(max-width:992px){
                    text-align: center !important;
                }
            }

            & .trial{
                font-size: 30px;
                line-height: 35px;
                font-weight: 200;
                @media(max-width: 992px){
                    max-width: 17ch;
                    margin: auto;
                }

                @media(min-width: 993px){
                    font-size: 35px;
                }

                &>em{
                    font-weight: 600;
                }
            }
            & .btn{
                font-weight: 600;
                padding: 16px 25px;
                margin-top: 35px;
                background-color: #A385FF !important;
            }

            & .bb{
                padding-top: 35px;
                padding-bottom: 50px;
                border-bottom: 1px solid #4d4c4c;
            }

            & h1{
                font-size: 14px;
                font-weight: 800;
                line-height: 22px;
                text-transform: uppercase;
                margin-top: 10px;
            }

            & h1.logo{
                font-family: 'Paysy-logo'!important;
                font-size: 40px !important;
                line-height: 40px !important;
                text-transform: lowercase !important;
                margin-top: 0;
            }

            & ul{
                list-style: none;
                display: inline-block;
                padding-left: 0;
                margin-top: 14px;

                & li{
                    margin-bottom: 12px;

                    & a{
                        color: inherit;
                        & img{
                            width: 150px;
                            @media(min-width: 993px){
                                width: 150px;
                            }
                        }
                    }

                    & .violet{
                        color: #A385FF !important;
                        font-size: 18px;
                    }
                }
            }
            & .copy{
                font-size: 12px;
                line-height: 12px;
                padding: 20px 0;
            }
        }

        & #intro{
            padding-top: 100px;
            position: relative;

            & h1{
                font-size: 30px;
                line-height: 37px;
                padding-top: 20px;
                letter-spacing: 0.43px;

                @media (min-width: 993px){
                    font-size: 70px;
                    line-height: 72px;
                    padding-top: 50px;
                    max-width: 580px;
                }
            }
            & h1 .color-primary{
                color: $color-primary;
            }

            & p{
                font-size: 15px;
                line-height: 25px;
                padding-top: 20px;
                color: $color-p;
                em{
                    color: $color-p-dark;
                }
                @media (min-width: 993px){
                    max-width: 600px;
                }
                @media (max-width: 992px){
                    text-align: justify;
                }

                & em{
                    font-style: normal;
                    font-weight: 700;
                }

            }


            & .btn{
                padding: 20px 35px;
                font-size: 15px;
                font-weight: 800;
                margin-top: 40px;
                @media (max-width: 992px){
                    padding: 16px 30px;
                    margin-top: 10px;
                    margin-left: 5%;
                    margin-right: 5%;
                    width: 90%;
                }
            }
            & img{
                width: 410px;
                height: 447px;
                padding-top: 35px;
                @media (max-width: 992px){
                    width: calc(100vw - 50px);

                    margin-top: 15px;
                }
            }
        }


        & #benefits{
            padding-top: 160px;
            position: relative;
            @media (max-width: 992px){
                padding-top: 0;
            }


            & h1{
                font-size: 15px;
                line-height: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
                font-weight: 700;
                color: #000C2F;

                &:after{
                    display: inline-block;
                    margin: 0 .5rem;
                    transform: translateY(-4px);
                    content: "";
                    width: 70%;
                    height: 2px;
                    background-image: linear-gradient(to right, #BDA2FA, #F4F1FB);
                    @media (max-width: 992px){
                        width: 45%;
                    }
                }
            }
            & h1.title{
                font-size: 35px;
                line-height: 40px;
                font-weight: 600;
                &:after{
                    display: none;
                }
            }
            & img.member-card{
                max-width: 295px;
            }
            & img.dots{
                width: 314px;
                position: absolute;
                left: 1130px;
                top: 10px;
                @media(min-width:1460px)
                {
                    left:1430px;
                }
            }
            & a.btn{
                width: 290px;
            }

            & .item{
                margin-top: 30px;
                & h2{
                    color: #000C2F;
                    font-size: 20px;
                    line-height: 27px;
                    font-weight: 600;
                    padding-left: 52px;
                    margin: 0;
                    @media (max-width: 992px){
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                & p{
                    color: #90929C;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: 400;
                    max-width: 440px;
                    padding-left: 52px;
                    padding-top: 20px;
                    margin: 0;
                    text-align: left;

                }

                &:before{
                    content: "";
                    display: block;
                    width: 27px;
                    height: 22px;
                    background-image: url('/images/checkmark.png');
                    background-size: contain;
                    margin-top: 3px;
                    float: left;
                }

            }
        }

        & #benefits.cards{
            padding-top: 60px;
            @media (max-width: 992px){
                padding-top: 0px;
            }

        }
        & #howto{
            padding-top: 100px;
            position:relative;
            @media (max-width: 992px){
                padding-top: 0;
            }

            & h1{
                font-size: 50px;
                line-height: 72px;
                letter-spacing: .714px;
                font-weight: 600;
                @media (max-width: 992px){
                    font-size: 30px;
                    line-height: 37px;
                }
            }
            & h2{
                font-size: 35px;
                line-height: 48px;
                font-weight: 600;
                margin-top: 50px;
                margin-bottom: 30px;
                @media (max-width: 992px){
                    font-size: 20px;
                    line-height: 27px;
                    margin-top: 12px;
                    margin-bottom: 20px;
                }
            }
            & h3{
                color: #000C2F;
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                @media (max-width: 992px){
                    font-size: 15px;
                }
            }
            & h4{
                color: #000C2F;
                font-size: 20px;
                line-height: 20px;
                font-weight: 400;
                @media (max-width: 992px){
                    font-size: 15px;
                    padding: 0 10px;
                }
            }
            & .justify{
                padding: 0 10px;
                text-align: center;
                & img{
                    width: 210px;
                    @media (max-width: 992px){
                        width:100%;
                    }
                }
            }

            & span{
                position: absolute;
                width: 860px;
                height: 310px;
                background-image: linear-gradient(to bottom, #F2EEFE, #FAF8FF);

                top: 160px;
                left:55%;
                transform: skewY(-5deg);
                @media (max-width: 992px){
                    width: 170px;
                    height:65px ;
                    top:395px;
                    left:56%;
                }

                &.small{
                    top:100px;
                    left: 53%;
                    width: 332px;
                    height: 110px;
                    @media (max-width: 992px){
                        width: 120px;
                        height: 45px;
                        top: 370px;
                        left:50%;
                    }
                }
            }

        }
        & #variable-membership-card{
            position:relative;

            & img.dots{
                width: 314px;
                position: absolute;
                left: 0px;
                bottom: -150px;

            }

            & .item{
                margin-top: 30px;
            }
            & .item:first-of-type{
                margin-top: 0;
            }
            @media (max-width: 992px){

                h2{
                    font-size: 15px;
                    font-weight: 500;
                }
                h2.title{
                    margin-top: 50px;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 600;
                }
                .item{
                    margin-top: 30px;
                    line-height: 22px;
                }
            }

            & span{
                position: absolute;
                width: 860px;
                height: 310px;
                background-image: linear-gradient(to bottom, #F2EEFE, #FAF8FF);

                top: 26px;
                left:55%;
                transform: skewY(-5deg);
                @media (max-width: 992px){
                    width: 170px;
                    height:65px ;
                    top:395px;
                    left:56%;
                }

                &.small{
                    top:-26px;
                    left: 53%;
                    width: 332px;
                    height: 110px;
                    @media (max-width: 992px){
                        width: 120px;
                        height: 45px;
                        top: 370px;
                        left:50%;
                    }
                }
            }
        }

        & #services{
            padding-top: 60px;
            position: relative;
            @media (max-width: 992px){
                padding-top: 0;
            }

            & h1{
                font-size: 35px;
                line-height: 48px;
                padding-bottom: 50px;
                font-weight: 600;
                margin-bottom: 20px;
                color: #000C2F;
                @media (max-width: 992px){
                    font-size: 20px;
                    line-height: 27px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

            }
            & img.dots{
                width: 314px;
                position: absolute;
                right: 1130px;
                top: 305px;
                @media(min-width:1460px)
                {
                    right:1455px;
                }
            }

            & .item{
                margin-top: 30px;
                & h2{
                    color: #000C2F;
                    font-size: 20px;
                    line-height: 27px;
                    font-weight: 600;
                    padding-left: 52px;
                    margin: 0;
                }
                &:before{
                    content: "";
                    display: block;
                    width: 27px;
                    height: 22px;
                    background-image: url('/images/checkmark.png');
                    background-size: contain;
                    margin-top: 3px;
                    float: left;
                }
            }
            & p{
                color: #4a4a4a;
                font-size: 15px;
                line-height: 25px;
                font-weight: 400;
                max-width: 750px;
                margin-top: 70px;
                margin-bottom: 90px;
                text-align: justify;
                @media (max-width: 992px){
                    margin-top: 50px;
                }
            }
        }
        & #comunication{
            padding-top: 60px;
            position: relative;
            @media (max-width: 992px){
                padding-top: 0;
            }

            & h1{
                font-size: 35px;
                line-height: 48px;
                padding-bottom: 50px;
                font-weight: 600;
                margin-bottom: 20px;
                color: #000C2F;
                @media (max-width: 992px){
                    font-size: 20px;
                    line-height: 27px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

            }
            & img.dots{
                width: 314px;
                position: absolute;
                right: 1130px;
                top: 305px;
                @media(min-width:1460px)
                {
                    right:1455px;
                }
            }

            & .item{
                margin-top: 30px;
                & h2{
                    color: #000C2F;
                    font-size: 17px;
                    line-height: 22px;
                    font-weight: 600;
                    padding-left: 52px;
                    margin: 0;
                    @media (max-width: 992px)
                    {
                        font-size: 15px;
                        font-weight: normal;
                    }
                }
                &:before{
                    content: "";
                    display: block;
                    width: 27px;
                    height: 22px;
                    background-image: url('/images/checkmark.png');
                    background-size: contain;
                    margin-top: 3px;
                    float: left;
                }
            }
            & p{
                color: #4a4a4a;
                font-size: 15px;
                line-height: 25px;
                font-weight: 400;
                max-width: 750px;
                margin-top: 70px;
                margin-bottom: 90px;
                text-align: justify;
                @media (max-width: 992px){
                    margin-top: 50px;
                }
            }

            & img{
                @media (max-width: 992px){
                    margin-bottom: 50px;
                }
            }
        }
        & #distribution{
            padding-top: 60px;
            position: relative;
            @media (max-width: 992px){
                padding-top: 0;
            }

            & h1{
                font-size: 35px;
                line-height: 48px;
                font-weight: 600;
                color: #000C2F;
                @media (max-width: 992px){
                    font-size: 20px;
                    line-height: 27px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

            }
            & img{
                height: 85px;
            }
            & img.dots{
                width: 314px;
                position: absolute;
                right: 1130px;
                top: 305px;
                @media(min-width:1460px)
                {
                    right:1455px;
                }
            }

            & .item{
                margin-top: 30px;
                & h2{
                    color: #000C2F;
                    font-size: 17px;
                    line-height: 22px;
                    font-weight: 600;
                    padding-left: 52px;
                    margin: 0;
                }
                &:before{
                    content: "";
                    display: block;
                    width: 27px;
                    height: 22px;
                    background-image: url('/images/checkmark.png');
                    background-size: contain;
                    margin-top: 3px;
                    float: left;
                }
            }
            & p{
                color: #90929C;
                font-size: 15px;
                line-height: 28px;
                font-weight: 400;
                margin-top: 25px;
                margin-bottom: 30px;
                text-align: justify;
                @media (max-width: 992px){
                    margin-top: 20px;
                    margin-bottom: 50px;
                }
            }
            & h3{
                font-size: 20px;
                color: $color-title;
                text-align: center;
                margin-top: 30px;
                @media (max-width: 992px){
                    margin-bottom: 50px;
                }
            }
        }
    }

    & #benefits-setting{
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
        @media (max-width: 992px){
            padding-top: 0;
        }

        & h1{
            font-size: 35px;
            line-height: 48px;
            font-weight: 600;
            color: #000C2F;
            @media (max-width: 992px){
                font-size: 20px;
                line-height: 27px;
                padding-bottom: 0;
                margin-bottom: 0;
            }

        }
        & img{
            height: 85px;
        }
        & img.dots{
            width: 314px;
            position: absolute;
            right: 1130px;
            top: 305px;
            @media(min-width:1460px)
            {
                right:1455px;
            }
        }
        & h2{
            font-size: 20px;
            color: $color-title;
            line-height: 27px;
            font-weight: bold;
        }

        & .item{
            margin-top: 30px;
            & h2{
                color: #000C2F;
                font-size: 17px;
                line-height: 22px;
                font-weight: 600;
                padding-left: 52px;
                margin: 0;
            }
            &:before{
                content: "";
                display: block;
                width: 27px;
                height: 22px;
                background-image: url('/images/checkmark.png');
                background-size: contain;
                margin-top: 3px;
                float: left;
            }
        }
        & p{
            color: #90929C;
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;
            margin-top: 25px;
            margin-bottom: 30px;
            text-align: justify;
            @media (max-width: 992px){
                margin-top: 50px;
            }
        }
        & h3{
            font-size: 20px;
            color: $color-title;
            text-align: center;
            margin-top: 30px;
        }
        & img.dots{
            width: 314px;
            position: absolute;
            right: 0px;
            height: auto;
            bottom: 0px;

        }
    }
    & #sender:before{
        content: "";
        display: block;
        width: 100vw;
        height: 540px;
        margin: auto;
        background-image: linear-gradient(to bottom, #F2EEFE, #FAF8FF);
        position: absolute;
        top: -280px;
        transform: skewY(-5deg);
        @media (max-width: 992px){
            height: 200px;
            top:-1487px;
        }
    }
    & #crm:before{
        content: "";
        display: block;
        width: 100vw;
        height: 690px;
        margin: auto;
        background-image: linear-gradient(to bottom, #F2EEFE, #FAF8FF);
        position: absolute;
        top: -477px;
        transform: skewY(-4deg);
        @media (max-width: 992px){
            height: 200px;
            top:-1487px;
            display: none;
        }
    }

    & #landing_page, #crm{
        padding-top: 115px;
        position: relative;
        @media (max-width: 992px){
            padding-top: 0;
        }
        & h1{
            margin-top: 60px;
            color: #000;
            font-size: 35px;
            line-height: 35px;
            font-weight: 600;
            @media (max-width: 992px){
                margin-top: 0;
                font-size: 20px;
                line-height: 20px;
            }
        }
        & h2{
            color: #6c52b4;
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 25px;
            @media (max-width: 992px){
                font-size: 18px;
                line-height: 20px;
            }
        }
        & p{
            color: $color-p;
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 15px;
            text-align: left;
        }

        & img{
            width: 100%;
        }
    }

    & #crm{
        padding-top: 0;

        img.dots{
            width: 314px;
            position: absolute;
            left: 0px;
            bottom: -150px;
        }
    }

    & #sender, #reciever{
        padding-top: 115px;
        position: relative;
        @media (max-width: 992px){
            padding-top: 0;
        }
        & h1{
            margin-top: 60px;
            color: #000;
            font-size: 35px;
            line-height: 35px;
            @media (max-width: 992px){
                margin-top: 0;
                font-size: 20px;
                line-height: 20px;
            }
        }
        & h2{
            color: #6c52b4;
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 25px;
            @media (max-width: 992px){
                font-size: 18px;
                line-height: 20px;
            }
        }
        & p{
            color: #4a4a4a;
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 15px;
            text-align: left;
        }

        & img{
            width: 100%;
        }
    }
    & #reciever{
        & h1{
            @media (min-width: 993px) and (max-width: 1460px){
                margin-top: 35px;
            }
        }
    }
    & #sender{
        & h1{
            @media (min-width: 993px) and (max-width: 1460px){
                margin-top: 5px;
            }
        }
    }


}
.full-width{
    max-width: 100% !important;
}

.item:before{
    content: "";
    display: block;
    width: 27px;
    height: 22px;
    background-image: url("/images/checkmark.png");
    background-size: contain;
    margin-top: 3px;
    float: left;
}
.dropdown-menu .item:before{
    display: none;
}

.item h2{
    color: #000C2F;
    font-size: 16px;
    line-height: 27px;
    font-weight: 600;
    padding-left: 52px;
    margin: 0;
}

